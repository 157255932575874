@use '@angular/material' as mat; 
@include mat.core();

@import 'theme-reset';
.app{
    &.indigo-light{
        @import "skins/indigo-light";
        @include mat.all-component-colors($indigo-light-theme);
        @include theme-reset($indigo-light-theme);
    }
    &.teal-light{
        @import "skins/teal-light";
        @include mat.all-component-colors($teal-light-theme);
        @include theme-reset($teal-light-theme);
    }
     &.red-light{
        @import "skins/red-light";
        @include mat.all-component-colors($red-light-theme);
        @include theme-reset($red-light-theme);
    }
    &.blue-dark{
        @import "skins/blue-dark";
        @include mat.all-component-colors($blue-dark-theme);
        @include theme-reset($blue-dark-theme);
    }
    &.green-dark{
        @import "skins/green-dark";
        @include mat.all-component-colors($green-dark-theme);
        @include theme-reset($green-dark-theme);
    }
    &.pink-dark{
        @import "skins/pink-dark";
        @include mat.all-component-colors($pink-dark-theme);
        @include theme-reset($pink-dark-theme);
    }
}